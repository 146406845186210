import styled from "styled-components";

export const SignUpContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 500px;

	h2 {
		margin: 10px 0;
	}
`;
